import axios from 'axios'
import moment from 'moment'
import React from 'react'
import equipment from '../../../assets/equipment.json'
import Button from '../../Button'
import Feedback from '../../Feedback'
import { DateRange, Form, Input } from '../../Form'
import talley from '../talley'
import styles from './PortofoonForm.module.scss'




const currencyFormatter = new Intl.NumberFormat('nl-NL', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2,
})

class PortofoonForm extends React.Component {
  state = {
    error: null,
    message: null,
    totalPrice: 0,
  }

  _handleSubmit = async (formData) => {
    try {
      await this.setState({
        error: null,
        message: null,
      })

      await axios.post(
        'https://staticmailer.pexel.nl/9JLXe5omba21',
        formData.values
      )

      if (window.ga) {
        window.ga('send', 'event', 'portofoonformulier', 'verzenden')
      }

      await this.setState({
        message:
          'Uw aanvraag is verzonden, wij nemen zo spoedig mogelijk contact met u op.',
      })
    } catch (error) {
      if (window.ga) {
        window.ga('send', 'event', 'portofoonformulier', 'error')
      }

      await this.setState({
        error:
          'Er is een fout opgetreden en uw aanvraag is niet verzonden.\nProbeer het later nog eens of stuur uw aanvaag per e-mail naar info@wenrbeveiliging.nl.',
      })

      throw new Error(error.message)
    }
  }

  _setItemDescription = (item) => {
    const constructedDescription = []

    if (item.description) {
      constructedDescription.push(`${item.description}\n`)
    }

    if (item.pricing) {
      const { day, week, weekend } = item.pricing

      if (day) {
        constructedDescription.push(
          `  - Dagtarief: ${currencyFormatter.format(day)}`
        )
      }
      if (weekend) {
        constructedDescription.push(
          `  - Weekendtarief: ${currencyFormatter.format(weekend)}`
        )
      }
      if (week) {
        constructedDescription.push(
          `  - Weektarief: ${currencyFormatter.format(week)}`
        )
      }
    } else {
      constructedDescription.push('Tarieven op aanvraag')
    }

    return constructedDescription.filter((entry) => entry).join('\n')
  }

  _handleChange = (values) => {
    const totalPrice = talley(values).endTotal
    this.setState({ totalPrice })
  }

  render() {
    const { message, error } = this.state

    // prettier-ignore
    const tomorrow = moment().add(1, 'days').toDate()

    const initialValues = {
      period: [tomorrow, tomorrow],
    }

    return (
      <>
        {error && <Feedback type="error">{error}</Feedback>}
        {message && <Feedback type="success">{message}</Feedback>}

        <Form
          initialValues={initialValues}
          onChange={this._handleChange}
          onSubmit={this._handleSubmit}
        >
          <fieldset>
            <legend>Contactgegevens</legend>
            <Input label="Naam" name="name" required type="text" />
            <Input label="Bedrijf" name="company" type="text" />
            <Input label="Adres" name="address" required type="text" />
            <Input label="Plaats" name="city" required type="text" />
            <Input label="E-mailadres" name="email" required type="email" />
            <Input label="Telefoon" name="phone" required type="tel" />
          </fieldset>

          <fieldset>
            <legend>Periode</legend>
            {/* prettier-ignore */}
            <DateRange
              label="Periode"
              min={moment().add(1, 'days').toDate()}
              max={moment().add(1, 'years').toDate()}
              required
              name="period"
            />
          </fieldset>

          <fieldset>
            <legend>Materiaal</legend>

            {equipment.map((item) => (
              <div className={styles.materiaal}>
                <Input
                  key={item.id}
                  label={item.name}
                  min="0"
                  name={`materiaal_${item.id}`}
                  placeholder="0"
                  type="number"
                />

                <span style={{ whiteSpace: 'pre-wrap' }}>
                  {this._setItemDescription(item)}
                </span>
              </div>
            ))}
          </fieldset>

          <br />

          <div className={styles.totaalPrijs}>
            <span className="t-heading-xs" style={{ color: '#000000' }}>
              Indicatie totaalprijs:{' '}
              {currencyFormatter.format(this.state.totalPrice)}
            </span>
            <span className="t-italic t-small">
              Excl. BTW en tarieven op aanvraag
            </span>
          </div>

          <br />

          <Button type="submit" color="confirm" size="large">
            Aanvraag versturen »
          </Button>
        </Form>
      </>
    )
  }
}

export default PortofoonForm
