import moment from 'moment'

import equipment from '../../assets/equipment.json'

moment.locale('nl')

const talley = (formData) => {
  const { period } = formData

  if (period && period[0] && period[1]) {
    /*
      ===========================
      Dates
      ===========================
    */

    // Enumerate dates
    let startDateMoment = moment(formData.period[0])
    let endDateMoment = moment(formData.period[1])

    const dates = [startDateMoment.clone()]

    // prettier-ignore
    while (startDateMoment.add(1, 'days').diff(endDateMoment, 'days') < 1) {
      dates.push(startDateMoment.clone())
    }

    dates.pop()

    // Talley weeks
    const weeks = dates.filter(
      (date, index) =>
        date.isoWeekday() === 1 && // day is monday
        dates[index + 6] && // dates array is at least 6 days longer (a next sunday exists)
        dates[index + 6].isoWeekday() === 7 // 6 days from date is a sunday
    ).length

    // Talley weekends
    const weekends = Math.ceil(
      dates.filter((date) => date.isoWeekday() >= 6).length / 2 - weeks
    )

    // Talley loose days
    // prettier-ignore
    const days = dates.filter(
      (date) => date.isoWeekday() <= 5 // day is weekday
    ).length - weeks * 5 // number of weekdays minus full weeks

    /*
      ===========================
      Materials
      ===========================
    */

    // Extract equipment talley from form data
    const items = []

    Object.entries(formData)
      .filter((entry) => entry[0].match(/^materiaal_.+$/))
      .forEach((item) => {
        const itemId = item[0].replace(/^materiaal_/, '')
        const itemAmt = item[1]
        const equipmentData = equipment.find(
          (equipmentItem) => equipmentItem.id === Number(itemId)
        )

        const talliedItem = {
          ...equipmentData,
          amount: itemAmt,
        }

        const itemPricing = equipmentData.pricing

        if (itemPricing) {
          const weekendsSubtotal = itemAmt * weekends * itemPricing.weekend
          const weeksSubtotal = itemAmt * weeks * itemPricing.week
          const daysSubtotal = itemAmt * days * itemPricing.day
          const total = weekendsSubtotal + weeksSubtotal + daysSubtotal

          talliedItem.total = {
            weekendsSubtotal,
            weeksSubtotal,
            daysSubtotal,
            total,
          }
        }

        items.push(talliedItem)
      })

    /*
      ===========================
      End total
      ===========================
    */

    let endTotal = 0

    items.forEach((item) => {
      if (item.total) {
        endTotal += item.total.total
      }
    })

    return {
      items,
      endTotal,
    }
  }

  return {
    items: [],
    endTotal: 0,
  }
}

export default talley
