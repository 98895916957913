import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Button from '../components/Button'
import Content from '../components/Content'
import PortofoonForm from '../components/PagePortofoonverhuur/PortofoonForm'
import Root from '../layouts/Root'

import config from '../config'

export default ({ data }) => {
  const { childImageSharp } = data.file

  return (
    <Root>
      {/* prettier-ignore */}
      <Helmet>
        <title>{`Aanvraagformulier portofoonverhuur | ${config.title}`}</title>
        <meta name="description" content='Portofoons huren? Onze professionele Kenwood portofoons zijn uitermate geschikt voor de communicatie tijdens een evenement, sportwedstrijd of festival.' />
        <meta property="og:title" content={`Aanvraagformulier portofoonverhuur | ${config.title}`} />
        <meta property="og:description" content='Portofoons huren? Onze professionele Kenwood portofoons zijn uitermate geschikt voor de communicatie tijdens een evenement, sportwedstrijd of festival.' />
        <meta property="og:image" content={config.url + childImageSharp.fluid.src} />
        <meta property="og:image:height" content={childImageSharp.original.height} />
        <meta content={childImageSharp.original.width} />
      </Helmet>

      <main>
        <Content
          title="Aanvraagformulier portofoonverhuur"
          image={childImageSharp.fluid}
          cta={() =>
            // prettier-ignore
            <>
              <Button color="confirm" onClick={() => window.history.go(-1)} size="large">Terug</Button>
              <Button type="link" to="/diensten/" size="large">Bekijk alle diensten</Button>
            </>
          }
        >
          <div>
            <p>
              Gebruik het onderstaande formulier om een aanvraag in te dienen.
              Na verzending ontvangt u per e-mail een overzicht. Uw aanvraag
              wordt zo spoedig mogelijk in behandeling genomen door een van onze
              medewerkers.
            </p>

            <ul className="t-small">
              <li>
                De vermelde prijzen zijn per stuk, exclusief BTW en onder
                voorbehoud van wijzigingen.
              </li>
              <li>
                De vermelde totaalprijs is slechts een indicatie en geen aanbod.
              </li>
              <li>
                Portofoons worden verhuurd inclusief laadstations en koffer.
              </li>
              <li>
                Verhuur per losse dag in het weekend is niet mogelijk, alleen
                voor zaterdag én zondag.
              </li>
              <li>
                Materialen huren in het weekend? Wees er tijdig bij omdat
                weekenden al vroeg volgeboekt zijn.
              </li>
            </ul>

            <PortofoonForm />
          </div>
        </Content>
      </main>
    </Root>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "pages/aanvraagformulier.png" }) {
      childImageSharp {
        original {
          height
          width
        }
        fluid(maxWidth: 800, maxHeight: 600, toFormat: JPG) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
